<template>
    <v-container fluid :style="`width: 100vw; height: 100vh`" class="hideScroll ma-0 pa-0">
      <!-- Top bar -->
        <v-row v-if="!mobile" rows="1" justify="start" class="ma-0" style="width: 100%; height: 48px;">
            <v-btn
              text
              class="mt-2 ml-5"
              @click="$router.replace('/videos')"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h1 class="mt-1 ml-2" :style="`max-width: ${$vuetify.breakpoint.mobile ? '70%' : '75%'}; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;`">{{ streamName }}</h1>
            <div style="margin-left: auto;">
                <v-expand-x-transition v-if="streamPlaying">
                    <v-icon large color="red" class="blink mr-4 mt-2">mdi-circle</v-icon>
                </v-expand-x-transition>
            </div>
            <mobile-stream-btn v-if="mobile" class="mobileStreamBtn"></mobile-stream-btn>
        </v-row>
        <v-btn
            v-else
            style="position: absolute; left: 5px; top: 5px; opacity: .5;"
            text
            @click="$router.replace('/videos')"
          >
              <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

        <v-divider></v-divider>

      <!-- Main window, loading, permissions denied state or canvas with preview,  -->
        <v-sheet id="canvasContainer" :style="`width: 100%; display: flex; justify-content: center; align-items: center;`" :class="mainCanvas">
          <!-- Loading state -->
          <v-sheet v-if="loading || rotating" style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center;">
              <v-progress-circular
              indeterminate
              color="primary"/>
          </v-sheet>
          <!-- No permissions state -->
          <v-sheet v-else-if="!loading && !permissions && !rotating" style="height: 100%; width: 100%; background-color: #c7c7c7; display: flex; align-items: center; justify-content: center;">
              <v-card tonal style="height: 170px; width: 400px">
                  <v-card-title>{{ $t('streaming.no_devices') }}</v-card-title>
                  <v-card-subtitle>{{ $t('streaming.no_devices_subtitle') }}</v-card-subtitle>
                  <v-card-actions class="ml-2">
                      <v-btn @click="openTutorial">{{ $t('streaming.instructions') }}</v-btn>
                  </v-card-actions>
              </v-card>
          </v-sheet>
          <!-- Canvas element, where preview is attached -->
          <div v-show="!loading && permissions && !rotating" :style="`width: ${canvasWidth}; height: ${canvasHeight};`">
            <canvas ref="canvasElement" v-show="!loading && permissions" style="height: 100%; width: 100%"></canvas>
          </div>
        </v-sheet>

        <v-divider></v-divider>

      <!-- Audio and video selection bar, with toggle stream button -->
        <v-sheet width="100%" height="100px" :class="$vuetify.breakpoint.mobile ? 'mobileBottomBar' : ''">
            <streaming-bar :canvas="$refs.canvasElement"></streaming-bar>
        </v-sheet>

      <!-- Dialog with instructions to granting permissions  -->
        <v-dialog v-model="dialogOpen" max-width="350px">
            <permission-tutorial @close="closeTutorial()"></permission-tutorial>
        </v-dialog>

    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        StreamingBar: () => import("../components/streaming/StreamingBar.vue"),
        PermissionTutorial: () => import("../components/streaming/PermissionTutorial.vue"),
        MobileStreamBtn: () => import("../components/streaming/MobileStreamBtn.vue")
    },

    // On mounted, we get the permissions to userMedia and create a broadcast client and a preview with the init action.
    // We also add an event listener to scale the preview on resize and scale it to the initial size of the screen.
    mounted() {
      if(this.$route.path.includes('mobile')) this.setMobile(true);
      else this.setMobile(false)
      this.init(this.$refs.canvasElement)
      this.$nextTick(() => {
        window.addEventListener("resize", this.handleResize);
      });
      this.updateCanvasDimensions(document.getElementById("canvasContainer"))
    },

    // Before axiting the view, we remove the streamName from localstorage and the eventlistener for resize
    beforeDestroy() {
      // implement safe exit?
        localStorage.removeItem("streamName");
        window.removeEventListener("resize", this.handleResize);
        this.clear()
    },
    methods: {
        ...mapActions('streaming', [
          'init',
          'openTutorial',
          'closeTutorial',
          'updateCanvasDimensions',
          'setMobile',
          'rotationLoading',
          'clear'
        ]),

        // Calls the function to scale stream previews canvas element (the div it is wrapped in)
        handleResize() {
          if(this.$vuetify.breakpoint.mobile) {
            this.rotationLoading(true)
            setTimeout(() => {
              this.updateCanvasDimensions(document.getElementById("canvasContainer"));
              this.rotationLoading(false)
            }, 200)
          }
          else this.updateCanvasDimensions(document.getElementById("canvasContainer"));
        }
    },
    computed: {
        ...mapGetters('streaming', [
            'permissions',
            'loading',
            'dialogOpen',
            'canvasHeight',
            'canvasWidth',
            'streamPlaying',
            // 'mobile',
            'rotating'
        ]),
        mobile() {
          return this.$vuetify.breakpoint.mobile
        },
        streamName() {
            let str = window.localStorage.getItem('streamName')
            if(str) return JSON.parse(str)
            return "Name not set"
        },
        mainCanvas() {
          if(this.$vuetify.breakpoint.mobile) return 'mobileCanvas'
          return 'desktopCanvas'
        }
    },
}   
</script>

<style scoped>
  .blink {
        animation: blink-animation 1.5s steps(30, start) infinite;
        -webkit-animation: blink-animation 1.5s steps(30, start) infinite;
      }
      @keyframes blink-animation {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @-webkit-keyframes blink-animation {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      .desktopCanvas {
        height: calc(100% - 154px);
      }
      @media (orientation: landscape) {
        .mobileStreamBtn {
          display: inline;
        }
        /* .mobileBottomBar {
          display: none;
        } */
        .mobileCanvas {
          height: calc(100% - 56px);
        }
      }   

      @media (orientation: portrait) {
        .mobileStreamBtn {
          display: none;
        }
        .mobileBottomBar {
          display: inline;
        }
        .mobileCanvas {
          height: calc(100% - 154px);
        }
      }
</style>
